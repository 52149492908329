<template>
  <b10-base>
    <b10-toolbar
      :title="title"
      back
    />
    <b10-page-content>
      <v-sheet
        class="ma-2"
      >
        <v-calendar
          v-model="fecha"
          type="month"
          :events="formattedEventos"
          event-color="secondary"
          :weekdays="[1, 2, 3, 4, 5, 6, 0]"
          @change="changeCalendar"
          @click:event="clickEvento"
        />
      </v-sheet>
      <b10-fab-button
        left
        :icon="$vuetify.icons.values.prev"
        @click="clickSiguienteAnteriorMes(-1)"
      />
      <b10-fab-button
        :icon="$vuetify.icons.values.next"
        @click="clickSiguienteAnteriorMes(1)"
      />
    </b10-page-content>
    <v-dialog
      v-model="showingDialogs.turno"
    >
      <v-card
        v-if="selectedEvento"
      >
        <v-card-title>
          {{ selectedEvento.puesto_servicio_desc }} - {{ selectedEvento.puesto_servicio_servicio_desc }}
        </v-card-title>
        <v-card-text>
          <ul>
            <li>Desde: {{ selectedEvento.fdesde|shortDateTime }}</li>
            <li>Hasta: {{ selectedEvento.fhasta|shortDateTime }}</li>
            <li>Turno: {{ selectedEvento.tturno_descripcion }} ({{ selectedEvento.tturno_alias }})</li>
          </ul>
        </v-card-text>
      </v-card>
    </v-dialog>
  </b10-base>
</template>

<script>
import { basePageMixin } from '@/mixins/basePageMixin'
import Data from './CuadranteAgendaData'
import { addMonths, firstDayOfMonth, format as formatDate, currentDate, toDate } from '@/utils/date'
import { get } from 'vuex-pathify'

export default {
  mixins: [basePageMixin],
  beforeRouteLeave (to, from, next) {
    return this.beforeRouteLeaveBase(to, from, next)
  },
  data () {
    return {
      fecha: null,
      showingDialogs: {
        turno: false,
      },
      eventos: [],
      selectedEvento: null,
    }
  },
  computed: {
    usuarioIdvigilante: get('usuario/idvigilante'),
    mesAnio () {
      return this.fecha && formatDate(toDate(this.fecha), 'MMMM yyyy')
    },
    formattedEventos () {
      const eventos = []
      for (const evento of this.eventos) {
        eventos.push({
          start: evento.fdesde,
          end: evento.fdesde,
          name: evento.puesto_servicio_desc,
          data: evento
        })
      }
      return eventos
    },
  },
  async created () {
    await this.initStore()
    await this.loadPage()
  },
  methods: {
    async loadPage () {
      if (!this.fecha) {
        this.fecha = firstDayOfMonth(currentDate())
      }
      const [dataset] = await Data.selectCuadrante(
        this, this.usuarioIdvigilante, toDate(this.fecha)
      )
      this.eventos = dataset
    },
    async changeCalendar () {
      await this.loadPage()
      this.title = `Cuadrante ${this.mesAnio}`
    },
    clickEvento (evento) {
      this.selectedEvento = evento.event.data
      this.showingDialogs.turno = !this.showingDialogs.turno
    },
    async clickSiguienteAnteriorMes (offset) {
      this.fecha = addMonths(toDate(this.fecha), offset)
    },
  }
}
</script>
