import { render, staticRenderFns } from "./CuadranteAgenda.vue?vue&type=template&id=870e3a76&"
import script from "./CuadranteAgenda.vue?vue&type=script&lang=js&"
export * from "./CuadranteAgenda.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCalendar } from 'vuetify/lib/components/VCalendar';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VSheet } from 'vuetify/lib/components/VSheet';
installComponents(component, {VCalendar,VCard,VCardText,VCardTitle,VDialog,VSheet})
