import { firstDayOfMonth, lastDayOfMonth } from '@/utils/date'
import { APIFilter } from '@/utils/api'

export default {
  async selectCuadrante (Vue, idvigilante, fecha) {
    const apiFilter = new APIFilter()
    const fdesde = firstDayOfMonth(fecha)
    const fhasta = lastDayOfMonth(fecha)
    apiFilter
      .addExact('idvigilante', idvigilante)
      .addGT('estado', 0)
      .addGTE('fecha', fdesde)
      .addLTE('fecha', fhasta)
    let resp = await Vue.$api.call('cuadrante.select', { filter: apiFilter })
    return [resp.data.result.dataset, resp.data.result.metadata]
  }
}
